.privacypolicy-main{
    margin: 100px 0;
}
.privacypolicy-main h2{
    margin-bottom: 30px;
    text-decoration: underline;
    font-family: 'Roboto Slab', serif ;
}
.privacypolicy-main h3{
    margin-bottom: 18px;
    font-family: 'Roboto Slab', serif ;
}

.privacypolicy-main ul{
    list-style-type: none;
    padding-left: 0rem;
}

.privacypolicy-main ul li h4{
    font-size: 18px;
    font-family: 'Roboto Slab', serif ;
    margin-top: 30px;
    margin-bottom: 15px;
}