.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;  
    height: 50px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Roboto Slab', serif ;
    width: 100%;
    padding: 0px 12px;
    box-shadow: 0px 0px 20px rgba(0,0,0,.3);
}
.footer .links .navbar .nav .footer-link{
    color: #000;
}
.copyrightText p{
    margin: 0;
}